import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { getProgrammingAssignmentModelTestsFile } from '../../../http-actions/assignment-actions';
import { AuthContext } from '../../../providers/auth-provider';
import CustomButton from '../../button';

interface AssignmentModelSolutionDownloadButtonI {
    assignmentId: number | undefined;
}

const AssignmentTestsDownloadButton = ({
    assignmentId,
}: AssignmentModelSolutionDownloadButtonI) => {
    const { state: authState } = useContext(AuthContext);

    const downloadTests = async () => {
        if (!assignmentId || !authState.token) return;

        // get data to download
        const data = await getProgrammingAssignmentModelTestsFile(authState.token, assignmentId);

        // check for errors
        if (!data || data?.err) return;

        // TODO: parse title to filename, check filenamify npm module out

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `assignment-${assignmentId}-tests.zip`); // TODO: parse title to filename, check filenamify npm module out
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <CustomButton
            color="inherit"
            onClick={downloadTests}
            kind="fitech"
            variant="contained"
            disableElevation
        >
            <Trans i18nKey="downloadTestCode" />
        </CustomButton>
    );
};

export default AssignmentTestsDownloadButton;
