import { Box, Paper, Typography, Divider, Tabs, Tab, AppBar } from '@mui/material';
import React, { useEffect } from 'react';
import LikertScale from '../../../likert-slider';
import sxPageStyles from '../../styles/page-styles';
import {
    ProgrammingAssignmentHelpRequestI,
    ProgrammingAssignmentI,
    SubmissionI,
} from '../../types';
import HelpRequestResponseForm from '../../view/help-request-response-form';
import { getStatus } from '../util/help-request-status';

import HelpRequestResponseList from '../../view/help-request-response-list';
import StatusTags from './status-tags';

interface HelpRequestResponseContainerProps {
    selectedHelpRequest: ProgrammingAssignmentHelpRequestI | undefined;
    submissionForSelectedHelpRequest: SubmissionI | undefined;
    programmingAssignmentForSelectedHelpRequest: ProgrammingAssignmentI | undefined;
    submitHelpRequestResponse: (data: {
        response: string;
        programmingAssingmentHelpRequestId: number;
        metadata?: Record<string, unknown> | undefined;
    }) => void;
    toggleStatusFilter: (
        status: 'responded' | 'isResolved' | 'notResolved' | 'notResponded',
    ) => () => void;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
    return {
        id: `response-activity-tab-${index}`,
        'aria-controls': `response-activity-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            display={value !== index ? 'none' : 'flex'}
            flexGrow={1}
            flexDirection="column"
            overflow="hidden"
            role="tabpanel"
            id={`response-activity-tabpanel-${index}`}
            aria-labelledby={`response-activity-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </Box>
    );
}

const HelpRequestResponseContainer = ({
    selectedHelpRequest,
    submissionForSelectedHelpRequest,
    programmingAssignmentForSelectedHelpRequest,
    submitHelpRequestResponse,
    toggleStatusFilter,
}: HelpRequestResponseContainerProps) => {
    const [tabValue, setTabValue] = React.useState(0);

    const showResponseContainer =
        submissionForSelectedHelpRequest &&
        programmingAssignmentForSelectedHelpRequest &&
        selectedHelpRequest;

    useEffect(() => {
        setTabValue(0);
    }, [showResponseContainer]);

    if (!showResponseContainer) return null;

    const getStatusStyle = (
        hr: ProgrammingAssignmentHelpRequestI,
        type: 'isResolved' | 'responded',
    ) => {
        const status = getStatus(hr, type);
        return sxPageStyles[status];
    };

    const statusStyle = getStatusStyle(selectedHelpRequest, 'responded');

    const handleTabChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
        setTabValue(newValue);
    };

    const onFormComplete = () => {
        const feedbackTabValue = 1;
        setTabValue(feedbackTabValue);
    };

    const notResponded = getStatus(selectedHelpRequest, 'responded') === 'notResponded';

    return (
        <Paper elevation={2} sx={sxPageStyles.editorContainerRestrictHeight}>
            <Box display="flex" flexGrow="1" flexDirection="column" overflow="hidden">
                <Box
                    data-e2e-hook={`help-request-header-${selectedHelpRequest?.helpRequestUuid}`}
                    className="help-request-row"
                    display="flex"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                >
                    <Box
                        className="help-request-header"
                        flexDirection="column"
                        display="flex"
                        width="100%"
                    >
                        <Typography variant="h3" className="help-request-title">
                            {programmingAssignmentForSelectedHelpRequest?.title || 'Loading...'}
                        </Typography>
                        <Typography className="help-request-help-request-id-subtitle">
                            {`Request id: ${selectedHelpRequest?.helpRequestUuid}`}
                        </Typography>
                        <Typography className="help-request-user-id-subtitle">
                            {`User id: ${selectedHelpRequest?.userId}`}
                        </Typography>
                    </Box>
                    <StatusTags
                        helpRequest={selectedHelpRequest}
                        toggleStatusFilter={toggleStatusFilter}
                    />
                    <Box className="help-request-created-at">
                        {new Date(selectedHelpRequest?.createdAt || '').toLocaleString()}
                    </Box>
                </Box>
                <Divider />
                <AppBar elevation={0} color="transparent" position="static" sx={statusStyle}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        TabIndicatorProps={{
                            sx: sxPageStyles.responseActivityTabIndicator,
                        }}
                        aria-label="Choose to read responses or create new response"
                    >
                        <Tab sx={sxPageStyles.tabTextColor} label="Responses" {...a11yProps(0)} />
                        <Tab
                            sx={sxPageStyles.tabTextColor}
                            disabled={notResponded}
                            label="Feedback"
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </AppBar>
                <TabPanel value={tabValue} index={0}>
                    <Box sx={{ overflowX: 'auto', overflowY: 'scroll' }}>
                        <HelpRequestResponseList
                            responses={
                                selectedHelpRequest?.programmingAssignmentHelpRequestResponses
                            }
                            helpRequestUserId={selectedHelpRequest?.userId}
                        />
                        <HelpRequestResponseForm
                            submitHelpRequestResponse={submitHelpRequestResponse}
                            selectedHelpRequest={selectedHelpRequest}
                            onComplete={onFormComplete}
                        />
                    </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <LikertScale
                        name="2020-help-request-template-questions-likert-rating"
                        question="The problem was described well by the student"
                        data={{
                            helpRequestId: selectedHelpRequest.id,
                            helpRequestUuid: selectedHelpRequest.helpRequestUuid,
                            helpRequestTemplateId: selectedHelpRequest.helpRequestTemplateId,
                        }}
                    />
                    <Divider />
                    <LikertScale
                        name="2020-help-request-template-questions-likert-rating-2"
                        question="The description helped me solve the problem"
                        data={{
                            helpRequestId: selectedHelpRequest.id,
                            helpRequestUuid: selectedHelpRequest.helpRequestUuid,
                            helpRequestTemplateId: selectedHelpRequest.helpRequestTemplateId,
                        }}
                    />
                </TabPanel>
            </Box>
        </Paper>
    );
};

export default HelpRequestResponseContainer;
