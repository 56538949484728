import { Box, Button } from '@mui/material';
import { Trans } from 'react-i18next';
import React from 'react';
import { ProgrammingAssignmentHelpRequestI } from '../../types';
import { getStatus } from '../util/help-request-status';

const sxStyles = {
    statusTitle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    statusTagButton: (theme) => ({
        textDecoration: 'none',
        padding: theme.spacing(0, 1),
        whiteSpace: 'nowrap',
        margin: theme.spacing(0, 1),
    }),
    notResolved: {
        backgroundColor: '#ff983f',
    },
    notResponded: {
        backgroundColor: '#ff983f',
    },
    inProgress: {
        color: '#fff',
        backgroundColor: '#a836ad',
    },
    isResolved: {
        color: '#fff',
        backgroundColor: '#388e3c',
    },
    responded: {
        color: '#fff',
        backgroundColor: '#168AFD',
    },
    unseenResponses: {
        backgroundColor: '#fee12b',
        color: '#000',
    },
};


interface StatusTagsPropsI {
    helpRequest: ProgrammingAssignmentHelpRequestI | undefined;
    toggleStatusFilter: (status: string) => () => void;
}

const StatusTags = ({ helpRequest, toggleStatusFilter }: StatusTagsPropsI) => {

    if (!helpRequest) return null;

    const createStatusFilterToggle = (type: string) => (event: React.MouseEvent) => {
        event.stopPropagation();
        const toggleCallback = toggleStatusFilter(type);
        toggleCallback();
    };

    const getStatusStyle = (
        hr: ProgrammingAssignmentHelpRequestI,
        type: 'isResolved' | 'responded',
    ) => {
        const status = getStatus(hr, type);
        return (theme) => ({
            ...sxStyles[status],
            ...sxStyles.statusTagButton(theme)
        });
    };

    const getStatusText = (
        hr: ProgrammingAssignmentHelpRequestI,
        type: 'isResolved' | 'responded',
    ) => {
        const status = getStatus(hr, type);
        return <Trans i18nKey={status} />;
    };

    return (
        <Box
            className="help-request-tag-wrapper"
            flexDirection="row"
            alignItems="center"
            display="flex"
        >
            <Button
                color="inherit"
                onClick={createStatusFilterToggle(getStatus(helpRequest, 'isResolved'))}
                sx={ getStatusStyle(helpRequest, 'isResolved') }
            >
                {getStatusText(helpRequest, 'isResolved')}
            </Button>
            <Button
                color="inherit"
                onClick={createStatusFilterToggle(getStatus(helpRequest, 'responded'))}
                sx={ getStatusStyle(helpRequest, 'responded') }
            >
                {getStatusText(helpRequest, 'responded')}
            </Button>
        </Box>
    );
};

export default StatusTags;
