import React, { useContext } from 'react';
import { HelpRequestAdminPageDataContext } from '../../help-request-teacher-page-data-provider';
import { HelpRequestAdminPageFilterContext } from '../../help-request-teacher-page-filter-provider';
import {
    ProgrammingAssignmentHelpRequestI,
    ProgrammingAssignmentI,
    SubmissionI,
} from '../../types';
import HelpRequestResponseContainer from './help-request-response-container';

const HelpRequestResponseContainerWithContext = () => {
    const {
        selectedHelpRequest,
        submissionForSelectedHelpRequest,
        programmingAssignmentForSelectedHelpRequest,
        submitHelpRequestResponse,
    } = useContext(HelpRequestAdminPageDataContext);

    const { toggleStatusFilter } = useContext(HelpRequestAdminPageFilterContext);

    return (
        <HelpRequestResponseContainer
            selectedHelpRequest={selectedHelpRequest as ProgrammingAssignmentHelpRequestI}
            submitHelpRequestResponse={submitHelpRequestResponse}
            submissionForSelectedHelpRequest={submissionForSelectedHelpRequest as SubmissionI}
            programmingAssignmentForSelectedHelpRequest={
                programmingAssignmentForSelectedHelpRequest as ProgrammingAssignmentI
            }
            toggleStatusFilter={toggleStatusFilter}
        />
    );
};

export default HelpRequestResponseContainerWithContext;
