import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { getProgrammingAssignmentSubmissionFile } from '../../../http-actions/assignment-actions';
import { AuthContext } from '../../../providers/auth-provider';
import CustomButton from '../../button';

interface AssignmentSubmissionDownloadButtonI {
    submissionId: number | undefined;
}

const AssignmentStarterDownloadButton = ({ submissionId }: AssignmentSubmissionDownloadButtonI) => {
    const { state: authState } = useContext(AuthContext);

    const downloadSubmission = async () => {
        if (!submissionId || !authState.token) return;

        // GET DATA TO DOWNLOAD
        const data = await getProgrammingAssignmentSubmissionFile(authState.token, submissionId);

        // CHECH FOR ERROR
        if (!data || data?.err) return;

        // TODO: parse title to filename, check filenamify npm module out

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `submission.zip`); // TODO: parse title to filename, check filenamify npm module out
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <CustomButton
            color="inherit"
            onClick={downloadSubmission}
            kind="fitech"
            variant="contained"
            disableElevation
        >
            <Trans i18nKey="downloadSubmissionCode" />
        </CustomButton>
    );
};

export default AssignmentStarterDownloadButton;
