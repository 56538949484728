import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, SvgIconTypeMap } from '@mui/material';
import {
    SentimentSatisfied,
    SentimentSatisfiedAltOutlined,
    SentimentDissatisfiedOutlined,
    SentimentVeryDissatisfiedOutlined,
    SentimentVerySatisfiedOutlined,
} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { postAnalyticsData } from '../http-actions/analytics-actions';
import { AuthContext } from '../providers/auth-provider';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { UserContext } from '../providers/user-provider';

interface LikertMark {
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    value: number;
    label: string;
}

const marks: LikertMark[] = [
    {
        icon: SentimentVeryDissatisfiedOutlined,
        value: -2,
        label: 'Strongly disagree',
    },
    {
        icon: SentimentDissatisfiedOutlined,
        value: -1,
        label: 'Disagree',
    },
    {
        icon: SentimentSatisfied,
        value: 0,
        label: 'Neutral',
    },
    {
        icon: SentimentSatisfiedAltOutlined,
        value: 1,
        label: 'Agree',
    },
    {
        icon: SentimentVerySatisfiedOutlined,
        value: 2,
        label: 'Strongly agree',
    },
];

const getAsHistoryEntryFormat = (name: string, data: unknown, userId: number) =>
    `${name}-${JSON.stringify(data)}-${userId}`;

const markAsRatedInLocalStorage = (name: string, data: unknown, userId: number) => {
    const entry = getAsHistoryEntryFormat(name, data, userId);
    const history = localStorage.getItem('likert-rating-history');

    if (!history) {
        localStorage.setItem('likert-rating-history', JSON.stringify([entry]));
    } else {
        const parsedHistory = JSON.parse(history);
        const updatedHistory = JSON.stringify([...parsedHistory, entry]);
        localStorage.setItem('likert-rating-history', updatedHistory);
    }
};

export default function LikertScale({
    name,
    question,
    data,
}: {
    name: string;
    question: string;
    data: Record<string, unknown>;
}) {
    const [ratedHighlightValue, setRatedHighlightValue] = useState(-999);
    const [disabled, setDisabled] = useState(false);

    const { state: authState } = useContext(AuthContext);
    const { state: userState } = useContext(UserContext);

    useEffect(() => {
        if (userState.user) {
            const history = localStorage.getItem('likert-rating-history');
            if (history) {
                const parsed = JSON.parse(history);
                const entry = `${name}-${JSON.stringify(data)}-${userState.user.id}`;
                if (parsed.find((el) => el === entry)) {
                    setDisabled(true);
                }
            }
        }
    }, [userState.user, data]);

    const handleClick = (mark: LikertMark) => {
        if (!authState.token || !userState.user) return;
        const { label, value } = mark;

        setRatedHighlightValue(value);

        markAsRatedInLocalStorage(name, data, userState.user.id);

        void postAnalyticsData(authState.token, 'help-request-likert-rating', {
            name,
            question,
            label,
            value,
            ...data,
        });

        setDisabled(true);
    };

    return (
        <Box
            sx={{
                padding: (theme) => theme.spacing(2),
                width: '100%',
                display: 'grid',
                justifyItems: 'center',
            }}
        >
            <Typography variant="subtitle2" id="discrete-slider-likert" gutterBottom>
                {question}
            </Typography>
            <Box
                sx={{
                    width: '100%',
                    maxWidth: 600,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                }}
            >
                {marks.map((mark: LikertMark) => (
                    <IconButton
                        key={mark.label}
                        sx={{
                            '&:disabled': {
                                color:
                                    (ratedHighlightValue === mark.value && 'primary.main') ||
                                    undefined,
                            },
                        }}
                        disabled={disabled}
                        id={`likert scale ${mark.label}`}
                        aria-label={`likert scale ${mark.label}`}
                        onClick={() => handleClick(mark)}
                        size="large"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                maxWidth: 60,
                            }}
                        >
                            <mark.icon fontSize="large" />
                            <Typography
                                align="center"
                                variant="caption"
                                htmlFor={`likert scale ${mark.label}`}
                                component="label"
                            >
                                {mark.label}
                            </Typography>
                        </Box>
                    </IconButton>
                ))}
            </Box>
            {disabled && (
                <Typography
                    variant="body2"
                    id="discrete-slider-likert-already-completed"
                    gutterBottom
                >
                    Already completed. Thank you for answering.
                </Typography>
            )}
        </Box>
    );
}
