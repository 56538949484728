import {
    Box,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import React, { useContext } from 'react';

import { Trans } from 'react-i18next';

import sxPageStyles from '../../../styles/page-styles';

import { HelpRequestAdminPageFilterContext } from '../../../help-request-teacher-page-filter-provider';

export const StatusCheckboxGroup = () => {
    const { statusFilter, handleStatusFilterChange } = useContext(
        HelpRequestAdminPageFilterContext,
    );

    const { notResolved, notResponded, unseenResponses, isResolved, responded } = statusFilter;

    return (
        <Box sx={sxPageStyles.categoryFilter}>
            <FormControl component="fieldset" sx={sxPageStyles.formControl}>
                <FormLabel component="legend">Help request status</FormLabel>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={sxPageStyles.pendingCheckbox}
                                color="default"
                                checked={notResponded}
                                onChange={handleStatusFilterChange}
                                name="notResponded"
                            />
                        }
                        label={<Trans i18nKey="notResponded" />}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={sxPageStyles.unseenResponsesCheckbox}
                                checked={unseenResponses}
                                color="default"
                                onChange={handleStatusFilterChange}
                                name="unseenResponses"
                            />
                        }
                        label={<Trans i18nKey="unseenResponses" />}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={sxPageStyles.respondedCheckbox}
                                checked={responded}
                                color="default"
                                onChange={handleStatusFilterChange}
                                name="responded"
                            />
                        }
                        label={<Trans i18nKey="responded" />}
                    />
                </FormGroup>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={sxPageStyles.pendingCheckbox}
                                checked={notResolved}
                                color="default"
                                onChange={handleStatusFilterChange}
                                name="notResolved"
                            />
                        }
                        label={<Trans i18nKey="notResolved" />}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={sxPageStyles.resolvedCheckbox}
                                checked={isResolved}
                                color="default"
                                onChange={handleStatusFilterChange}
                                name="isResolved"
                            />
                        }
                        label={<Trans i18nKey="isResolved" />}
                    />
                </FormGroup>
            </FormControl>
        </Box>
    );
};

export default StatusCheckboxGroup;
