import { Box, Collapse, Divider, ListItem, Paper, Typography } from '@mui/material';
import React from 'react';
import {
    ProgrammingAssignmentHelpRequestI,
    ProgrammingAssignmentI,
    SubmissionI,
    HelpRequestStatus,
} from '../../types';
import HelpRequestActions from './help-request-actions';
import HelpRequestContent from './help-request-content';
import StatusTags from './status-tags';

interface HelpRequestListItemProps {
    helpRequest: ProgrammingAssignmentHelpRequestI;
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
    selectedHelpRequest: ProgrammingAssignmentHelpRequestI | undefined;
    submissions: Record<string, SubmissionI>;
    programmingAssignments: Record<string, ProgrammingAssignmentI>;
    toggleStatusFilter: (status: HelpRequestStatus) => () => void;
    toggleUserFilter: (id: string) => () => void;
    toggleProgrammingAssignmentFilter: (id: string) => () => void;
    toggleTemplateFilter: (id: string) => () => void;
    handleSetDateValue: (endpoint: 'startDate' | 'endDate', dateToSet: Date) => void;
}

const HelpRequestListItem = ({
    helpRequest,
    onClick,
    selectedHelpRequest,
    submissions,
    programmingAssignments,
    toggleStatusFilter,
    toggleUserFilter,
    toggleProgrammingAssignmentFilter,
    toggleTemplateFilter,
    handleSetDateValue,
}: HelpRequestListItemProps) => {
    const submissionId = helpRequest.programmingAssignmentSubmissionId;
    const submissionForListItem = submissions[submissionId];
    const programmingAssignmentId = submissionForListItem?.programmingAssignmentId || '-1';
    const programmingAssignmentForListItem = programmingAssignments[programmingAssignmentId];
    return (
        <>
            <ListItem
                id={`help-request-list-item-${helpRequest.helpRequestUuid}`}
                key={helpRequest.helpRequestUuid}
                onClick={onClick}
                button
                disableRipple
                divider
                selected={selectedHelpRequest?.helpRequestUuid === helpRequest.helpRequestUuid}
            >
                <Box
                    data-e2e-hook={`help-request-header-${helpRequest.helpRequestUuid}`}
                    className="help-request-row"
                    display="flex"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box
                        className="help-request-header"
                        flexDirection="column"
                        display="flex"
                        width="100%"
                    >
                        <Typography variant="h3" className="help-request-title">
                            {`${helpRequest?.assignmentTitle || 'Loading...'}`}
                        </Typography>
                        <Typography className="help-request-user-id-subtitle">
                            {`User id: ${helpRequest.userId}`}
                        </Typography>
                    </Box>
                    <StatusTags helpRequest={helpRequest} toggleStatusFilter={toggleStatusFilter} />
                    <Box className="help-request-created-at">
                        {new Date(helpRequest.createdAt).toLocaleString()}
                    </Box>
                </Box>
            </ListItem>
            <Collapse
                in={selectedHelpRequest?.helpRequestUuid === helpRequest.helpRequestUuid}
                key={`help-requests-content-collapse-${helpRequest.helpRequestUuid}`}
                mountOnEnter
                unmountOnExit
            >
                <Paper square elevation={4}>
                    <HelpRequestActions
                        helpRequest={helpRequest}
                        submission={submissionForListItem}
                        programmingAssignment={programmingAssignmentForListItem}
                        toggleStatusFilter={toggleStatusFilter}
                        toggleUserFilter={toggleUserFilter}
                        toggleProgrammingAssignmentFilter={toggleProgrammingAssignmentFilter}
                        toggleTemplateFilter={toggleTemplateFilter}
                        handleSetDateValue={handleSetDateValue}
                    />
                    <Divider />
                    <HelpRequestContent
                        helpRequest={helpRequest}
                        submission={submissionForListItem}
                        programmingAssignment={programmingAssignmentForListItem}
                    />
                </Paper>
            </Collapse>
        </>
    );
};

export default React.memo(HelpRequestListItem);
