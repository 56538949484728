import {
    Box,
    Typography,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import React, { useContext } from 'react';

import sxPageStyles from '../../styles/page-styles';

import { HelpRequestAdminPageFilterContext } from '../../help-request-teacher-page-filter-provider';
import TemplateSelect from './filters/template-select';
import UserSelect from './filters/user-select';
import ProgrammingAssignmentSelect from './filters/programming-assignment-select';
import StatusCheckboxGroup from './filters/status-checkbox-group';
import DateRangePickerFilter from './filters/date-range-picker-filter';
import HelpRequestSelect from './filters/help-request-select';
import LanguageFilterCheckboxGroup from './filters/language-filter';
import ModuleCollectionSelect from './filters/module-collection-select';

export const HelpRequestFilterPanel = () => {
    const {
        filtersExpanded,
        templateSelected,
        userSelected,
        programmingAssignmentSelected,
        datePicked,
        handleFilterAccordionToggle,
        clearDateValue,
        clearProgrammingAssignmentSelect,
        clearTemplateSelect,
        clearUserSelect,
    } = useContext(HelpRequestAdminPageFilterContext);

    const showAccordion =
        templateSelected || userSelected || programmingAssignmentSelected || Boolean(datePicked);

    const handleClearAllFilters = () => {
        clearDateValue();
        clearProgrammingAssignmentSelect();
        clearTemplateSelect();
        clearUserSelect();
    };

    return (
        <Accordion expanded={filtersExpanded} onChange={handleFilterAccordionToggle}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="filters-content"
                id="filters-header"
            >
                <Typography sx={sxPageStyles.accordionHeading}>Filters</Typography>
                <Box sx={sxPageStyles.accordionSecondaryHeading}>
                    {showAccordion && (
                        <Chip
                            label="Enabled"
                            sx={sxPageStyles.chip}
                            onDelete={handleClearAllFilters}
                        />
                    )}
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={sxPageStyles.filterContentWrapper}>
                <Box sx={sxPageStyles.filterContent}>
                    <TemplateSelect />
                    <UserSelect />
                    <ProgrammingAssignmentSelect />
                    <HelpRequestSelect />
                    <StatusCheckboxGroup />
                    <ModuleCollectionSelect />
                    {/* <LanguageFilterCheckboxGroup /> */}
                    <DateRangePickerFilter />
                    <Button
                        color="inherit"
                        fullWidth
                        onClick={() => handleFilterAccordionToggle(null, false)}
                    >
                        <ExpandLessIcon />
                    </Button>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default HelpRequestFilterPanel;
