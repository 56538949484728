import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { getProgrammingAssignmentStarterFile } from '../../../http-actions/assignment-actions';
import { AuthContext } from '../../../providers/auth-provider';
import CustomButton from '../../button';

interface AssignmentStarterDownloadButtonI {
    assignmentId: number | undefined;
    assignmentTitle: string | undefined;
}

const AssignmentStarterDownloadButton = ({ assignmentId }: AssignmentStarterDownloadButtonI) => {
    const { state: authState } = useContext(AuthContext);

    const downloadStarter = async () => {
        if (!assignmentId || !authState.token) return;

        // GET DATA TO DOWNLOAD
        const data = await getProgrammingAssignmentStarterFile(authState.token, assignmentId);

        // CHECH FOR ERROR
        if (!data || data?.err) return;

        // TODO: parse title to filename, check filenamify npm module out

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `assignment-${assignmentId}-starter.zip`); // TODO: parse title to filename, check filenamify npm module out
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <CustomButton
            color="inherit"
            onClick={downloadStarter}
            kind="fitech"
            variant="contained"
            disableElevation
        >
            <Trans i18nKey="downloadStarterCode" />
        </CustomButton>
    );
};

export default AssignmentStarterDownloadButton;
