import { ProgrammingAssignmentHelpRequestI } from '../../types';

export const getStatus = (
    hr: ProgrammingAssignmentHelpRequestI,
    type: 'isResolved' | 'responded',
) => {
    switch (type) {
        case 'isResolved': {
            const isResolved = hr?.isResolved;
            if (isResolved) return 'isResolved';
            else return 'notResolved';
        }
        case 'responded': {
            const unseenResponses = hr?.unseenResponseCount > 0;
            const responded = hr?.responseCount > 0;
            if (unseenResponses) return 'unseenResponses';
            else if (responded) return 'responded';
            else return 'notResponded';
        }
        default:
            return 'notResolved';
    }
};
