import { Box, Button, Divider, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import React from 'react';

import AssignmentStarterDownloadButton from '../../button/assignment-starter-download-button';
import AssignmentSubmissionDownloadButton from '../../button/assignment-submission-download-button';
import OpenAssignmentInMaterialButton from '../../button/open-assignment-in-material-button';
import AssignmentModelSolutionDownloadButton from '../../button/assignment-model-solution-download-button';
import sxPageStyles from '../../styles/page-styles';
import {
    ProgrammingAssignmentHelpRequestI,
    ProgrammingAssignmentI,
    SubmissionI,
} from '../../types';
import { getStatus } from '../util/help-request-status';
import AssignmentTestsDownloadButton from '../../button/assignment-tests-download-button';

interface HelpRequestActionsProps {
    helpRequest: ProgrammingAssignmentHelpRequestI | undefined;
    submission: SubmissionI | undefined;
    programmingAssignment: ProgrammingAssignmentI | undefined;
    toggleStatusFilter: (
        status: 'responded' | 'isResolved' | 'notResolved' | 'notResponded',
    ) => () => void;
    toggleUserFilter: (id: string) => () => void;
    toggleProgrammingAssignmentFilter: (id: string) => () => void;
    toggleTemplateFilter: (id: string) => () => void;
    handleSetDateValue: (endpoint: 'startDate' | 'endDate', dateToSet: Date) => void;
}

const sxStyles = {
    infoBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    statusCategory: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 1,
        borderRadius: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    statusTitle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    pending: {
        backgroundColor: '#ff983f',
    },
    inProgress: {
        color: '#fff',
        backgroundColor: '#a836ad',
    },
    resolved: {
        color: '#fff',
        backgroundColor: '#388e3c',
    },
    responded: {
        backgroundColor: '#168AFD',
    },
};

const HelpRequestActions = ({
    helpRequest,
    submission,
    programmingAssignment,
    toggleStatusFilter,
    toggleUserFilter,
    toggleProgrammingAssignmentFilter,
    toggleTemplateFilter,
    handleSetDateValue,
}: HelpRequestActionsProps) => {
    if (!(helpRequest && programmingAssignment && submission))
        return (
            <Box id="help-request-actions" display="flex" width="100%" px={2} my={2}>
                <Typography variant="subtitle1">Loading...</Typography>
            </Box>
        );

    const createStatusFilterToggle = (
        type: 'responded' | 'isResolved' | 'notResolved' | 'notResponded',
    ) => (event: React.MouseEvent) => {
        event.stopPropagation();
        const toggleCallback = toggleStatusFilter(type);
        toggleCallback();
    };

    const getStatusStyle = (
        hr: ProgrammingAssignmentHelpRequestI,
        type: 'isResolved' | 'responded',
    ) => {
        const status = getStatus(hr, type);
        return {
            ...sxStyles.statusCategory,
            ...sxPageStyles[status]
        };
    };

    const getStatusText = (
        hr: ProgrammingAssignmentHelpRequestI,
        type: 'isResolved' | 'responded',
    ) => {
        const status = getStatus(hr, type);
        return <Trans i18nKey={status} />;
    };

    return (
        <Box
            id="help-request-actions"
            display="flex"
            flexDirection="column"
            flexGrow="0"
            width="100%"
        >
            <Box
                display="grid"
                gap="9px"
                gridTemplateColumns="repeat(auto-fit, minmax(128px, 1fr))"
                p={2}
                width="100%"
            >
                <Box sx={sxStyles.infoBox}>
                    <Typography sx={sxStyles.statusTitle} variant="subtitle2">
                        Status
                    </Typography>
                    <Button
                        color="inherit"
                        disableElevation
                        onClick={createStatusFilterToggle(getStatus(helpRequest, 'isResolved'))}
                        sx={ getStatusStyle(helpRequest, 'isResolved') }
                    >
                        {getStatusText(helpRequest, 'isResolved')}
                    </Button>
                </Box>
                <Box sx={sxStyles.infoBox}>
                    <Typography sx={sxStyles.statusTitle} variant="subtitle2">
                        User id
                    </Typography>
                    <Button
                        color="inherit"
                        variant="contained"
                        disableElevation
                        onClick={toggleUserFilter(String(helpRequest.userId))}
                        sx={sxStyles.statusCategory}
                    >
                        {helpRequest.userId}
                    </Button>
                </Box>
                <Box sx={sxStyles.infoBox}>
                    <Typography sx={sxStyles.statusTitle} variant="subtitle2">
                        Template id
                    </Typography>
                    <Button
                        color="inherit"
                        variant="contained"
                        disableElevation
                        onClick={toggleTemplateFilter(String(helpRequest.helpRequestTemplateId))}
                        sx={sxStyles.statusCategory}
                    >
                        {helpRequest.helpRequestTemplateId}
                    </Button>
                </Box>
                <Box sx={sxStyles.infoBox}>
                    <Typography sx={sxStyles.statusTitle} variant="subtitle2">
                        Programming assignment
                    </Typography>
                    <Button
                        color="inherit"
                        variant="contained"
                        disableElevation
                        onClick={toggleProgrammingAssignmentFilter(
                            String(programmingAssignment.title),
                        )}
                        sx={sxStyles.statusCategory}
                    >
                        <Typography variant="button" style={{ maxWidth: '128px' }} noWrap>
                            {programmingAssignment.title}
                        </Typography>
                    </Button>
                </Box>
                <Box sx={sxStyles.infoBox}>
                    <Typography sx={sxStyles.statusTitle} variant="subtitle2">
                        Date
                    </Typography>
                    <Button
                        color="inherit"
                        variant="contained"
                        disableElevation
                        onClick={() =>
                            handleSetDateValue('startDate', new Date(helpRequest.createdAt))
                        }
                        sx={sxStyles.statusCategory}
                    >
                        <Typography variant="button" style={{ maxWidth: '128px' }} noWrap>
                            {new Date(helpRequest.createdAt).toLocaleDateString()}
                        </Typography>
                    </Button>
                </Box>
            </Box>
            <Divider />
            <Box mx={2} mb={1} mt={2} display="flex" justifyContent="flex-start" flexWrap="wrap">
                <Box mr={1} mb={1}>
                    <AssignmentStarterDownloadButton
                        assignmentId={programmingAssignment.id}
                        assignmentTitle={programmingAssignment.title}
                    />
                </Box>
                <Box mr={1} mb={1}>
                    <AssignmentSubmissionDownloadButton submissionId={submission.id} />
                </Box>
                <Box mr={1} mb={1}>
                    <AssignmentModelSolutionDownloadButton
                        assignmentId={programmingAssignment.id}
                    />
                </Box>
                <Box mr={1} mb={1}>
                    <AssignmentTestsDownloadButton
                        assignmentId={programmingAssignment.id}
                    />
                </Box>
                <Box mr={1} mb={1}>
                    <OpenAssignmentInMaterialButton
                        moduleCollectionName={helpRequest.moduleCollectionName}
                        programmingAssignmentId={programmingAssignment.id}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default HelpRequestActions;
