import { Box, Divider, TablePagination } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { HelpRequestAdminPageDataContext } from '../../help-request-teacher-page-data-provider';
import { HelpRequestAdminPageFilterContext } from '../../help-request-teacher-page-filter-provider';
import sxPageStyles from '../../styles/page-styles';
import { ProgrammingAssignmentHelpRequestI } from '../../types';
import HelpRequestList from './help-request-list';

const HelpRequestListContainer = () => {
    const {
        helpRequests,
        submissions,
        programmingAssignments,
        selectedHelpRequest,
        handleHelpRequestSelect,
        courseLang,
    } = useContext(HelpRequestAdminPageDataContext);

    const {
        selectedTemplates,
        selectedUsers,
        selectedProgrammingAssignments,
        selectedHelpRequests,
        selectedModuleCollections,
        dateFilterValue,
        statusFilter,
        templateSelected,
        userSelected,
        programmingAssignmentSelected,
        moduleCollectionSelected,
        helpRequestSelected,
        datePicked,
        handleSetDateValue,
        toggleUserSelect,
        toggleProgrammingAssignmentSelect,
        toggleTemplateSelect,
        toggleStatusFilter,
        displayHelpRequestCurrentLanguage,
    } = useContext(HelpRequestAdminPageFilterContext);

    const { isResolved, unseenResponses, responded, notResolved, notResponded } = statusFilter;

    const isInDateRange = (
        date: Date | undefined,
        startDate: Date | undefined,
        endDate: Date | undefined,
    ) => {
        const dwt = {
            date: date?.setHours(0, 0, 0, 0),
            startDate: startDate?.setHours(0, 0, 0, 0),
            endDate: endDate?.setHours(0, 0, 0, 0),
        };
        if (!dwt.date) return false;
        if (dwt.startDate && dwt.endDate)
            return dwt.startDate <= dwt.date && dwt.date <= dwt.endDate;
        if (dwt.startDate) return dwt.startDate <= dwt.date;
        if (dwt.endDate) return dwt.date <= dwt.endDate;
        return false;
    };

    const filterProgrammingAssignmentHelpRequests = (hr: ProgrammingAssignmentHelpRequestI) => {
        const templateMatch = selectedTemplates.includes(String(hr.helpRequestTemplateId));
        const userMatch = selectedUsers.includes(String(hr.userId));
        const helpRequestMatch = selectedHelpRequests.includes(String(hr.helpRequestUuid));
        const moduleCollectionMatch = selectedModuleCollections.includes(
            String(hr.moduleCollectionName),
        );

        let helpRequestLanguage = '';
        if (hr.content?.fi) helpRequestLanguage = 'fi';
        if (hr.content?.en) helpRequestLanguage = 'en';

        const currentLanguage = courseLang;
        let filteredByLanguage = false;
        if (displayHelpRequestCurrentLanguage)
            filteredByLanguage = helpRequestLanguage === currentLanguage;
        else filteredByLanguage = true;

        const programmingAssignmentMatch = selectedProgrammingAssignments.includes(
            hr.assignmentTitle,
        );

        const resolvedStatus = hr?.isResolved && isResolved;
        const hasResponses = hr?.responseCount > 0 && responded;
        const hasUnseenResponses = hr?.unseenResponseCount > 0 && unseenResponses;
        const notResolvedStatus = !hr?.isResolved && notResolved;
        const notRespondedStatus = hr?.responseCount === 0 && notResponded;

        const dateRangeMatch = isInDateRange(
            new Date(hr.createdAt),
            dateFilterValue[0].startDate,
            dateFilterValue[0].endDate,
        );

        const isSelected = selectedHelpRequest?.id === hr.id;

        const resolvedFiltersActiveStatus = {
            resolved: { active: isResolved, value: resolvedStatus },
            notResolved: {
                active: notResolved,
                value: notResolvedStatus,
            },
        };
        const respondedFiltersActiveStatus = {
            unseenResponses: { active: unseenResponses, value: hasUnseenResponses },
            responded: { active: responded, value: hasResponses && !(hr?.unseenResponseCount > 0) },
            notResponded: {
                active: notResponded,
                value: notRespondedStatus,
            },
        };
        const selectFiltersActiveStatus = {
            template: { active: templateSelected, value: templateMatch },
            user: { active: userSelected, value: userMatch },
            programmingAssignment: {
                active: programmingAssignmentSelected,
                value: programmingAssignmentMatch,
            },
            helpRequest: { active: helpRequestSelected, value: helpRequestMatch },
            date: { active: datePicked, value: dateRangeMatch },
            moduleCollection: { active: moduleCollectionSelected, value: moduleCollectionMatch },
        };

        // When selected we want to show the help request in the list no matter what
        if (isSelected) return true;

        const responseFilterMatch = Object.values(respondedFiltersActiveStatus)
            .filter((matcher) => matcher.active)
            .some((matcher) => matcher.value);
        const resolvedFilterMatch = Object.values(resolvedFiltersActiveStatus)
            .filter((matcher) => matcher.active)
            .some((matcher) => matcher.value);
        const selectFilterMatch = Object.values(selectFiltersActiveStatus)
            .filter((matcher) => matcher.active)
            .every((matcher) => matcher.value);

        return (
            responseFilterMatch && resolvedFilterMatch && selectFilterMatch
            // && filteredByLanguage
        );
    };

    const createComparisonByDate = (direction: 'ASC' | 'DESC') => {
        // TODO: make types generic and move to util
        switch (direction) {
            case 'ASC':
                return (
                    a: ProgrammingAssignmentHelpRequestI,
                    b: ProgrammingAssignmentHelpRequestI,
                ) => (new Date(a.createdAt) <= new Date(b.createdAt) ? -1 : 1);
            case 'DESC':
                return (
                    a: ProgrammingAssignmentHelpRequestI,
                    b: ProgrammingAssignmentHelpRequestI,
                ) => (new Date(a.createdAt) >= new Date(b.createdAt) ? -1 : 1);
        }
    };

    const comparisonByDate = useMemo(() => createComparisonByDate('ASC'), []);
    const filteredHelpRequests = helpRequests
        .sort(comparisonByDate)
        .filter(filterProgrammingAssignmentHelpRequests);

    // HANDLE PAGINATION

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const pageHelpRequests = filteredHelpRequests.slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage,
    );

    return (
        <>
            <Box sx={sxPageStyles.listContainer}>
                <HelpRequestList
                    selectedHelpRequest={selectedHelpRequest}
                    programmingAssignmentHelpRequests={pageHelpRequests}
                    handleHelpRequestSelect={handleHelpRequestSelect}
                    programmingAssignments={programmingAssignments}
                    submissions={submissions}
                    toggleStatusFilter={toggleStatusFilter}
                    toggleUserFilter={toggleUserSelect}
                    toggleProgrammingAssignmentFilter={toggleProgrammingAssignmentSelect}
                    toggleTemplateFilter={toggleTemplateSelect}
                    handleSetDateValue={handleSetDateValue}
                />
            </Box>
            <Divider />
            {pageHelpRequests.length > 0 && !selectedHelpRequest && (
                <TablePagination
                    sx={{ flexShrink: 0 }}
                    component="div"
                    count={filteredHelpRequests.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton
                    showLastButton
                />
            )}
        </>
    );
};

export default HelpRequestListContainer;
