import { Box, FormControl } from '@mui/material';
import lodash from 'lodash';
import React, { useContext } from 'react';
import Select from 'react-select';
import { HelpRequestAdminPageDataContext } from '../../../help-request-teacher-page-data-provider';
import { HelpRequestAdminPageFilterContext } from '../../../help-request-teacher-page-filter-provider';
import sxPageStyles from '../../../styles/page-styles';
import { HelpRequestTemplateI } from '../../../types';

export const TemplateSelect = () => {

    const { templates } = useContext(HelpRequestAdminPageDataContext);

    const { selectedTemplates, handleTemplateSelect } = useContext(
        HelpRequestAdminPageFilterContext,
    );

    const templateToOption = (template: HelpRequestTemplateI) => ({
        label: `id: ${template.id} (count: ${template?.helpRequestCount})`,
        value: String(template.id),
    });

    return (
        <Box sx={sxPageStyles.templateSelect}>
            <FormControl sx={sxPageStyles.formControl}>
                <Select
                    placeholder="Template"
                    id="template-select-multiple-chip"
                    isMulti
                    value={templates
                        .filter((template: HelpRequestTemplateI) =>
                            selectedTemplates.includes(String(template.id)),
                        )
                        .map(templateToOption)}
                    onChange={handleTemplateSelect}
                    options={templates.sort((a, b) => a.id - b.id).map(templateToOption)}
                />
            </FormControl>
        </Box>
    );
};

export default TemplateSelect;
