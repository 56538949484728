import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExpandMore } from '@mui/icons-material';
import clsx from 'clsx';
import React from 'react';

const PREFIX = 'TestResult';

const classes = {
    success: `${PREFIX}-success`,
    failure: `${PREFIX}-failure`,
    error: `${PREFIX}-error`,
    accordionCentered: `${PREFIX}-accordionCentered`,
    heading: `${PREFIX}-heading`,
    passedHeading: `${PREFIX}-passedHeading`,
    passedContainer: `${PREFIX}-passedContainer`,
    testRemarks: `${PREFIX}-testRemarks`,
    textColorBlack: `${PREFIX}-textColorBlack`,
    textColorWhite: `${PREFIX}-textColorWhite`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
    [`& .${classes.success}`]: {
        backgroundColor: theme.palette.result.success,
    },

    [`& .${classes.failure}`]: {
        backgroundColor: theme.palette.result.fail,
    },

    [`& .${classes.error}`]: {
        backgroundColor: theme.palette.result.error,
    },

    [`& .${classes.accordionCentered}`]: {
        textAlign: 'center',
    },

    [`& .${classes.heading}`]: {
        // fontSize: theme.typography.pxToRem(15),
        margin: 'auto',
    },

    [`& .${classes.passedHeading}`]: {
        textAlign: 'center',
    },

    [`& .${classes.passedContainer}`]: {
        display: 'flex',
        padding: theme.spacing(2, 0),
        // height: '60px',
        width: '100%',
    },

    [`& .${classes.testRemarks}`]: {
        flexDirection: 'column',
        overflowX: 'auto',
        // color: theme.palette.text.secondary,
        fontSize: theme.typography.subtitle2.fontSize,
    },

    [`& .${classes.textColorBlack}`]: {
        color: theme.palette.common.black,
    },

    [`& .${classes.textColorWhite}`]: {
        color: theme.palette.common.white,
    },
}));

interface TestResultI {
    passed: boolean;
    testName: string;
    testErrors: string;
}

const TestResult = (props: { testResult: TestResultI }) => {
    const { passed, testName, ...testRemarks } = props.testResult;
    const testRemarkEntries = Object.entries(testRemarks);
    const onlyOutputInTestRemarks =
        testRemarkEntries.length === 1 && testRemarkEntries[0][0] == 'test output';
    return (
        <Root>
            {passed ? (
                <Paper
                    className={clsx(classes.passedContainer, {
                        [classes.error]: props.testResult.testErrors,
                        [classes.success]: passed,
                        [classes.failure]: !passed,
                    })}
                >
                    <Typography
                        className={clsx(classes.heading, classes.passedHeading, {
                            [classes.textColorBlack]: !props.testResult.testErrors && !passed,
                            [classes.textColorWhite]: props.testResult.testErrors || passed,
                        })}
                        variant="body2"
                    >
                        {testName}
                    </Typography>
                </Paper>
            ) : (
                <Accordion
                    className={clsx({
                        [classes.error]: props.testResult.testErrors,
                        [classes.success]: passed,
                        [classes.failure]: !passed,
                    })}
                >
                    <AccordionSummary
                        expandIcon={passed || <ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.accordionCentered}
                    >
                        <Typography
                            className={clsx(classes.heading, {
                                [classes.textColorBlack]: !props.testResult.testErrors && !passed,
                                [classes.textColorWhite]: props.testResult.testErrors || passed,
                            })}
                            variant="body2"
                        >
                            {testName}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.testRemarks}>
                        {onlyOutputInTestRemarks ? (
                            <pre>{testRemarkEntries[0][1]}</pre>
                        ) : (
                            testRemarkEntries.map(([k, v]) => (
                                <pre key={k}>
                                    {k}: {v}
                                </pre>
                            ))
                        )}
                    </AccordionDetails>
                </Accordion>
            )}
        </Root>
    );
};

export default TestResult;
