import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { getPoolByProgrammingAssignmentId } from '../../../http-actions/assignment-actions';
import { AuthContext } from '../../../providers/auth-provider';
import CustomButton from '../../button';
import { postAnalyticsData } from '../../../http-actions/analytics-actions';
import config from '../../../config';

interface OpenAssignmentInMaterialButtonI {
    moduleCollectionName: string;
    programmingAssignmentId: number | undefined;
}

const OpenAssignmentInMaterialButton = ({
    moduleCollectionName,
    programmingAssignmentId,
}: OpenAssignmentInMaterialButtonI) => {
    const { state: authState } = useContext(AuthContext);

    const moduleCollection = Object.keys(config.MODULE_COLLECTIONS).find(
        (mc) => config.MODULE_COLLECTIONS[mc].title === moduleCollectionName,
    );

    const navigateToAssignmentPoolInMaterial = async () => {
        if (!programmingAssignmentId || !authState.token) return;

        const response = await getPoolByProgrammingAssignmentId(
            authState.token,
            programmingAssignmentId,
        );

        if (response && response.err) return;
        const { module, submodule } = response;
        const pathForNewPage = `/${moduleCollection}/${module}/${submodule}`;
        // const pathForNewPageWithHeader = pathForNewPage + `/#pool-${response.poolUuid}-header-assignments-completed`;

        // Analytics...
        void postAnalyticsData(authState.token, 'navigation', {
            navigate_to: pathForNewPage,
            type: 'open-page-with-assignment-in-new-tab',
        });

        window.open(pathForNewPage);
    };

    return (
        <CustomButton
            color="inherit"
            onClick={navigateToAssignmentPoolInMaterial}
            kind="fitech"
            variant="contained"
            disableElevation
        >
            <Trans i18nKey="openAssignmentPageInNewTab" />
        </CustomButton>
    );
};

export default OpenAssignmentInMaterialButton;
