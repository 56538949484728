import { Paper, Box, Typography, Button } from '@mui/material';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import sxPageStyles from '../../styles/page-styles';

import { HelpRequestAdminPageDataContext } from '../../help-request-teacher-page-data-provider';
import { HelpRequestAdminPageFilterContext } from '../../help-request-teacher-page-filter-provider';

export const HelpRequestSummary = () => {
    const {
        unseenResponsesCount,
        notResolvedCount,
        notRespondedCount,
        isResolvedCount,
        respondedCount,
    } = useContext(HelpRequestAdminPageDataContext);

    const { toggleStatusFilter, statusFilter } = useContext(HelpRequestAdminPageFilterContext);

    const { isResolved, notResolved, notResponded, responded, unseenResponses } = statusFilter;

    const buttonStyle = (styles) => (theme) => ({
        ...sxPageStyles.statusCategory(theme),
        ...sxPageStyles.statusTagButton(theme),
        ...sxPageStyles.noBoxShadow,
        ...(styles ?? {}),
    });

    return (
        <Box sx={sxPageStyles.summaryBarContent}>
            <Button
                color="inherit"
                variant="contained"
                onClick={toggleStatusFilter('unseenResponses')}
                sx={unseenResponses ? buttonStyle(sxPageStyles.unseenResponses) : buttonStyle()}
            >
                <Trans i18nKey="unseenResponses" /> {unseenResponsesCount}
            </Button>
            <Button
                color="inherit"
                variant="contained"
                onClick={toggleStatusFilter('notResolved')}
                sx={notResolved ? buttonStyle(sxPageStyles.pending) : buttonStyle()}
            >
                <Trans i18nKey="notResolved" /> {notResolvedCount}
            </Button>
            <Button
                color="inherit"
                variant="contained"
                onClick={toggleStatusFilter('notResponded')}
                sx={notResponded ? buttonStyle(sxPageStyles.pending) : buttonStyle()}
            >
                <Trans i18nKey="notResponded" /> {notRespondedCount}
            </Button>
            <Button
                color="inherit"
                variant="contained"
                onClick={toggleStatusFilter('responded')}
                sx={responded ? buttonStyle(sxPageStyles.responded) : buttonStyle()}
            >
                <Trans i18nKey="responded" /> {respondedCount}
            </Button>
            <Button
                color="inherit"
                variant="contained"
                onClick={toggleStatusFilter('isResolved')}
                sx={isResolved ? buttonStyle(sxPageStyles.resolved) : buttonStyle()}
            >
                <Trans i18nKey="isResolved" /> {isResolvedCount}
            </Button>
        </Box>
    );
};

export default HelpRequestSummary;
