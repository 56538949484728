import _ from 'lodash';
import React, { createContext, useState } from 'react';
import localStorageUtil from '../../util/local-storage-util';
import { HelpRequestStatus } from './types';

type NewSelectedList = { label: string; value: string }[];

interface HelpRequestFilterContextI {
    selectedTemplates: string[];
    selectedUsers: string[];
    selectedProgrammingAssignments: string[];
    selectedHelpRequests: string[];
    selectedModuleCollections: string[];
    dateFilterValue: [
        {
            startDate: Date | undefined;
            endDate: Date | undefined;
            key: string;
        },
    ];
    statusFilter: {
        responded: boolean;
        unseenResponses: boolean;
        isResolved: boolean;
        notResponded: boolean;
        notResolved: boolean;
    };
    filtersExpanded: boolean;
    statusSelected: boolean;
    templateSelected: boolean;
    userSelected: boolean;
    programmingAssignmentSelected: boolean;
    moduleCollectionSelected: boolean;
    helpRequestSelected: boolean;
    datePicked: boolean;
    dateRangePopoverOpen: boolean;
    dateRangePopoverId: undefined | string;
    dateRangeAnchorEl: null | HTMLButtonElement;
    displayHelpRequestCurrentLanguage: boolean;
    handleUserSelect: (newSelected: NewSelectedList) => void;
    handleTemplateSelect: (newSelected: NewSelectedList) => void;
    handleProgrammingAssignmentSelect: (newSelected: NewSelectedList) => void;
    handleModuleCollectionSelect: (newSelected: NewSelectedList) => void;
    handleHelpRequestSelect: (newSelected: NewSelectedList) => void;
    handleSetDateValue: (endpoint: 'startDate' | 'endDate', dateToSet: Date) => void;
    handleStatusFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleFilterAccordionToggle: (
        _event: unknown,
        isExpanded: boolean | ((prevState: boolean) => boolean),
    ) => void;
    handleDateRangePopoverClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleDateRangePopoverClose: () => void;
    toggleUserSelect: (userId: string) => () => void;
    toggleProgrammingAssignmentSelect: (paId: string) => () => void;
    toggleModuleCollectionSelect: (paId: string) => () => void;
    toggleTemplateSelect: (templateId: string) => () => void;
    toggleHelpRequestSelect: (helpRequestUuid: string) => () => void;
    toggleStatusFilter: (status: HelpRequestStatus) => () => void;
    clearUserSelect: () => void;
    clearTemplateSelect: () => void;
    clearProgrammingAssignmentSelect: () => void;
    clearModuleCollectionSelect: () => void;
    clearHelpRequestSelect: () => void;
    clearDateValue: () => void;
    setDateFilterValue: React.Dispatch<
        React.SetStateAction<
            [
                {
                    startDate: Date | undefined;
                    endDate: Date | undefined;
                    key: string;
                },
            ]
        >
    >;
    toggleCurrentLanguageFilter: () => void;
}

interface HelpRequestAdminPageFilterProviderPropsI {
    [index: string]: unknown;
    pageContext: { courseName: string; chatLink: string };
    children: React.ReactNode;
}

const moduleCollectionHelpRequestLocalStorageKey =
    'teacher__help-request-filter__module-collection';

export const HelpRequestAdminPageFilterContext = createContext<HelpRequestFilterContextI>({
    selectedTemplates: [],
    selectedUsers: [],
    selectedProgrammingAssignments: [],
    selectedHelpRequests: [],
    selectedModuleCollections: [],
    dateFilterValue: [{ startDate: undefined, endDate: undefined, key: 'selection' }],
    statusFilter: {
        responded: false,
        unseenResponses: false,
        isResolved: false,
        notResponded: false,
        notResolved: false,
    },
    filtersExpanded: false,
    statusSelected: false,
    templateSelected: false,
    userSelected: false,
    programmingAssignmentSelected: false,
    moduleCollectionSelected: false,
    helpRequestSelected: false,
    datePicked: false,
    dateRangePopoverOpen: false,
    dateRangePopoverId: undefined,
    dateRangeAnchorEl: null,
    displayHelpRequestCurrentLanguage: true,
    handleUserSelect: () => undefined,
    handleTemplateSelect: () => undefined,
    handleProgrammingAssignmentSelect: () => undefined,
    handleModuleCollectionSelect: () => undefined,
    handleHelpRequestSelect: () => undefined,
    handleSetDateValue: () => undefined,
    handleStatusFilterChange: () => undefined,
    handleFilterAccordionToggle: () => undefined,
    handleDateRangePopoverClick: () => undefined,
    handleDateRangePopoverClose: () => undefined,
    toggleUserSelect: () => () => undefined,
    toggleProgrammingAssignmentSelect: () => () => undefined,
    toggleModuleCollectionSelect: () => () => undefined,
    toggleTemplateSelect: () => () => undefined,
    toggleHelpRequestSelect: () => () => undefined,
    toggleStatusFilter: () => () => undefined,
    clearUserSelect: () => undefined,
    clearTemplateSelect: () => undefined,
    clearProgrammingAssignmentSelect: () => undefined,
    clearModuleCollectionSelect: () => undefined,
    clearHelpRequestSelect: () => undefined,
    clearDateValue: () => undefined,
    setDateFilterValue: () => undefined,
    toggleCurrentLanguageFilter: () => undefined,
});

export const HelpRequestAdminPageFilterProvider = ({
    children,
}: HelpRequestAdminPageFilterProviderPropsI) => {
    const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [selectedProgrammingAssignments, setSelectedProgrammingAssignments] = useState<string[]>(
        [],
    );
    const [selectedModuleCollections, setSelectedModuleCollections] = useState<string[]>(
        localStorageUtil.getJson(moduleCollectionHelpRequestLocalStorageKey) ?? [],
    );
    const [selectedHelpRequests, setSelectedHelpRequests] = useState<string[]>([]);

    const [dateFilterValue, setDateFilterValue] = useState<
        [
            {
                startDate: Date | undefined;
                endDate: Date | undefined;
                key: string;
            },
        ]
    >([{ startDate: undefined, endDate: undefined, key: 'selection' }]);

    const [statusFilter, setStatusFilter] = useState({
        notResolved: true,
        responded: false,
        unseenResponses: true,
        isResolved: false,
        notResponded: true,
    });
    const { responded, unseenResponses, isResolved, notResolved, notResponded } = statusFilter;

    const [displayHelpRequestCurrentLanguage, setDisplayHelpRequestCurrentLanguage] =
        useState(true);

    // UI STATE
    const [filtersExpanded, setFiltersExpanded] = useState(false);

    const statusSelected =
        [responded, isResolved, unseenResponses, notResolved, notResponded].filter((v) => v)
            .length > 0;
    const templateSelected = selectedTemplates.length > 0;
    const userSelected = selectedUsers.length > 0;
    const programmingAssignmentSelected = selectedProgrammingAssignments.length > 0;
    const moduleCollectionSelected = selectedModuleCollections.length > 0;
    const helpRequestSelected = selectedHelpRequests.length > 0;
    const datePicked = Boolean(dateFilterValue[0].startDate || dateFilterValue[0].endDate);

    // ---------------- HANDLERS ---------------------------
    // ! The handlers should be moved to a specific component using it
    // * This would mean passing fewer functions in the provider...

    const createSelectHandler = (setSelected: (value: React.SetStateAction<string[]>) => void) => {
        const handleSelect = (newSelected: NewSelectedList) => {
            setSelected(newSelected.map((s) => s.value));
        };
        return handleSelect;
    };

    const handleTemplateSelect = createSelectHandler(setSelectedTemplates);
    const handleUserSelect = createSelectHandler(setSelectedUsers);
    const handleProgrammingAssignmentSelect = createSelectHandler(
        setSelectedProgrammingAssignments,
    );
    const handleModuleCollectionSelect = (newSelected: NewSelectedList) => {
        localStorageUtil.setItem(moduleCollectionHelpRequestLocalStorageKey, newSelected.map(s => s.value));
        return createSelectHandler(setSelectedModuleCollections)(newSelected);
    };
    const handleHelpRequestSelect = createSelectHandler(setSelectedHelpRequests);

    const createSelectToggle = (setSelected: (value: React.SetStateAction<string[]>) => void) => {
        const toggleSelect = (value: string) => () => {
            setSelected((prev) => {
                if (prev.includes(value)) {
                    return prev.filter((id) => id !== value);
                } else {
                    return [...prev, value];
                }
            });
        };
        return toggleSelect;
    };

    const toggleUserSelect = createSelectToggle(setSelectedUsers);
    const toggleProgrammingAssignmentSelect = createSelectToggle(setSelectedProgrammingAssignments);
    const toggleModuleCollectionSelect = createSelectToggle(setSelectedModuleCollections);
    const toggleTemplateSelect = createSelectToggle(setSelectedTemplates);
    const toggleHelpRequestSelect = createSelectToggle(setSelectedHelpRequests);

    const clearUserSelect = () => setSelectedUsers([]);
    const clearProgrammingAssignmentSelect = () => setSelectedProgrammingAssignments([]);
    const clearModuleCollectionSelect = () => setSelectedModuleCollections([]);
    const clearTemplateSelect = () => setSelectedTemplates([]);
    const clearHelpRequestSelect = () => setSelectedHelpRequests([]);

    const handleSetDateValue = (endpoint: 'startDate' | 'endDate', dateToSet: Date) => {
        setDateFilterValue((prev) => [{ ...prev[0], [endpoint]: dateToSet }]);
    };

    const clearDateValue = () => {
        setDateFilterValue([{ startDate: undefined, endDate: undefined, key: 'selection' }]);
    };

    const preventStatusFilterToggle = (status: HelpRequestStatus) => {
        const hasMoreThanOneActiveFilter = (filters: { [k: string]: boolean }) =>
            Object.values(filters).reduce(
                (accumulator, active) => (active ? accumulator + 1 : accumulator),
                0,
            ) > 1;

        const respondedFilters = _.pick(statusFilter, [
            'responded',
            'notResponded',
            'unseenResponses',
        ]) as { [k: string]: boolean };
        if (respondedFilters[status]) {
            if (!hasMoreThanOneActiveFilter(respondedFilters)) return true;
        }

        const resolvedFilters = _.pick(statusFilter, ['isResolved', 'notResolved']) as {
            [k: string]: boolean;
        };
        if (resolvedFilters[status]) {
            if (!hasMoreThanOneActiveFilter(resolvedFilters)) return true;
        }
    };

    const toggleStatusFilter = (status: HelpRequestStatus) => () => {
        if (preventStatusFilterToggle(status)) return;
        setStatusFilter((prev) => ({ ...prev, [status]: !prev[status] }));
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event?.target?.name) return;
        const name = event.target.name as HelpRequestStatus;
        if (preventStatusFilterToggle(name)) return;

        setStatusFilter((prev) => ({ ...prev, [name]: Boolean(event?.target?.checked) }));
    };

    const handleFilterAccordionToggle = (
        _event: unknown,
        isExpanded: boolean | ((prevState: boolean) => boolean),
    ) => {
        setFiltersExpanded(isExpanded);
    };

    // Date range handlers and anchor state

    const [dateRangeAnchorEl, setDateRangeAnchorEl] = React.useState<HTMLButtonElement | null>(
        null,
    );

    const handleDateRangePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDateRangeAnchorEl(event.currentTarget);
    };

    const handleDateRangePopoverClose = () => {
        setDateRangeAnchorEl(null);
    };

    const dateRangePopoverOpen = Boolean(dateRangeAnchorEl);
    const dateRangePopoverId = dateRangePopoverOpen ? 'date-range-popover' : undefined;

    const toggleCurrentLanguageFilter = () => {
        setDisplayHelpRequestCurrentLanguage((prev) => !prev);
    };

    const contextValue: HelpRequestFilterContextI = {
        // data
        selectedTemplates,
        selectedUsers,
        selectedProgrammingAssignments,
        selectedModuleCollections,
        selectedHelpRequests,
        dateFilterValue,
        statusFilter,
        filtersExpanded,
        statusSelected,
        templateSelected,
        userSelected,
        programmingAssignmentSelected,
        moduleCollectionSelected,
        helpRequestSelected,
        datePicked,
        dateRangePopoverOpen,
        dateRangePopoverId,
        dateRangeAnchorEl,
        displayHelpRequestCurrentLanguage,
        // handlers
        handleUserSelect,
        handleTemplateSelect,
        handleProgrammingAssignmentSelect,
        handleModuleCollectionSelect,
        handleHelpRequestSelect,
        handleSetDateValue,
        handleStatusFilterChange,
        handleFilterAccordionToggle,
        handleDateRangePopoverClick,
        handleDateRangePopoverClose,
        toggleUserSelect,
        toggleProgrammingAssignmentSelect,
        toggleModuleCollectionSelect,
        toggleTemplateSelect,
        toggleHelpRequestSelect,
        toggleStatusFilter,
        clearUserSelect,
        clearTemplateSelect,
        clearProgrammingAssignmentSelect,
        clearModuleCollectionSelect,
        clearHelpRequestSelect,
        clearDateValue,
        setDateFilterValue,
        toggleCurrentLanguageFilter,
    };

    return (
        <HelpRequestAdminPageFilterContext.Provider value={contextValue}>
            {children}
        </HelpRequestAdminPageFilterContext.Provider>
    );
};

export default HelpRequestAdminPageFilterProvider;
