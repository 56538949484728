import { Box, FormControl } from '@mui/material';
import React, { useContext } from 'react';
import Select from 'react-select';
import { HelpRequestAdminPageDataContext } from '../../../help-request-teacher-page-data-provider';
import { HelpRequestAdminPageFilterContext } from '../../../help-request-teacher-page-filter-provider';
import sxPageStyles from '../../../styles/page-styles';

export const UserSelect = () => {
    const { userIds } = useContext(HelpRequestAdminPageDataContext);

    const { selectedUsers, handleUserSelect } = useContext(HelpRequestAdminPageFilterContext);

    return (
        <Box sx={sxPageStyles.userSelect}>
            <FormControl sx={sxPageStyles.formControl}>
                <Select
                    placeholder="User"
                    id="user-select-multiple-chip"
                    isMulti
                    value={selectedUsers.map((userId) => ({
                        label: `ìd: ${userId}`,
                        value: userId,
                    }))}
                    onChange={handleUserSelect}
                    options={userIds.map((userId) => ({
                        label: `id: ${userId}`,
                        value: String(userId),
                    }))}
                />
            </FormControl>
        </Box>
    );
};

export default UserSelect;
