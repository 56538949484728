import { Box, FormControl } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import Select from 'react-select';
import { HelpRequestAdminPageDataContext } from '../../../help-request-teacher-page-data-provider';
import { HelpRequestAdminPageFilterContext } from '../../../help-request-teacher-page-filter-provider';
import sxPageStyles from '../../../styles/page-styles';
import { ProgrammingAssignmentHelpRequestI } from '../../../types';

export const HelpRequestSelect = () => {

    const { helpRequests } = useContext(HelpRequestAdminPageDataContext);

    const { selectedHelpRequests, handleHelpRequestSelect } = useContext(
        HelpRequestAdminPageFilterContext,
    );

    const helpRequestToOption = (helpRequest: ProgrammingAssignmentHelpRequestI) => ({
        label: `uuid: ${helpRequest.helpRequestUuid}`,
        value: String(helpRequest.helpRequestUuid),
    });

    const createComparisonByDate = (direction: 'ASC' | 'DESC') => {
        switch (direction) {
            case 'ASC':
                return (
                    a: ProgrammingAssignmentHelpRequestI,
                    b: ProgrammingAssignmentHelpRequestI,
                ) => (new Date(a.createdAt) <= new Date(b.createdAt) ? -1 : 1);
            case 'DESC':
                return (
                    a: ProgrammingAssignmentHelpRequestI,
                    b: ProgrammingAssignmentHelpRequestI,
                ) => (new Date(a.createdAt) >= new Date(b.createdAt) ? -1 : 1);
        }
    };

    const comparisonByDate = useMemo(() => createComparisonByDate('DESC'), []);

    return (
        <Box sx={sxPageStyles.helpRequestSelect}>
            <FormControl sx={sxPageStyles.formControl}>
                <Select
                    placeholder="Help request"
                    id="help-request-select-multiple-chip"
                    isMulti
                    value={helpRequests
                        .filter((helpRequest: ProgrammingAssignmentHelpRequestI) =>
                            selectedHelpRequests.includes(String(helpRequest.helpRequestUuid)),
                        )
                        .map(helpRequestToOption)}
                    onChange={handleHelpRequestSelect}
                    options={helpRequests.sort(comparisonByDate).map(helpRequestToOption)}
                />
            </FormControl>
        </Box>
    );
};

export default HelpRequestSelect;
