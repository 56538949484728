import { Box, Typography, Button, Popover } from '@mui/material';
import React, { useContext } from 'react';
import { DateRangePicker } from 'react-date-range';

import sxPageStyles from '../../../styles/page-styles';

import { HelpRequestAdminPageFilterContext } from '../../../help-request-teacher-page-filter-provider';

export const DateRangePickerFilter = () => {
    const {
        dateFilterValue,
        datePicked,
        dateRangePopoverOpen,
        dateRangePopoverId,
        dateRangeAnchorEl,
        handleDateRangePopoverClick,
        handleDateRangePopoverClose,
        clearDateValue,
        setDateFilterValue,
    } = useContext(HelpRequestAdminPageFilterContext);

    const startText = dateFilterValue[0].startDate?.toLocaleDateString() || 'Any';
    const endText = dateFilterValue[0].endDate?.toLocaleDateString() || 'Continuous';
    const buttonText = datePicked ? `${startText} – ${endText}` : 'Click to set range';

    return (
        <Box sx={sxPageStyles.dateRangePicker}>
            <Typography component="legend" color="textSecondary" variant="body1">
                Date range
            </Typography>
            <Box display="flex">
                <Button
                    color="inherit"
                    variant="contained"
                    aria-describedby={dateRangePopoverId}
                    disableElevation
                    onClick={handleDateRangePopoverClick}
                >
                    {buttonText}
                </Button>
                <Button
                    color="inherit"
                    sx={sxPageStyles.clearDateButton}
                    onClick={clearDateValue}
                >
                    Clear
                </Button>
            </Box>

            <Popover
                id={dateRangePopoverId}
                anchorEl={dateRangeAnchorEl}
                open={dateRangePopoverOpen}
                onClose={handleDateRangePopoverClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <DateRangePicker
                    onChange={(item) => setDateFilterValue([item.selection])}
                    initialFocusedRange={[0, 1]}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={dateFilterValue}
                    direction="horizontal"
                />
            </Popover>
        </Box>
    );
};

export default DateRangePickerFilter;
