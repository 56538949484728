import { Box, FormControl } from '@mui/material';
import React, { useContext } from 'react';
import Select from 'react-select';
import { getEllipsisText } from '../../../../../util/string-util';
import { HelpRequestAdminPageDataContext } from '../../../help-request-teacher-page-data-provider';
import { HelpRequestAdminPageFilterContext } from '../../../help-request-teacher-page-filter-provider';
import sxPageStyles from '../../../styles/page-styles';
import config from '../../../../../config'

export const ModuleCollectionSelect = () => {

    const moduleCollectionNames = Object.values(config.MODULE_COLLECTIONS).map(moduleCollection => moduleCollection.title);

    const { selectedModuleCollections, handleModuleCollectionSelect } = useContext(
        HelpRequestAdminPageFilterContext,
    );

    const moduleCollectionNameToOption = (title: string) => ({
        label: `${getEllipsisText(title, 30)}`,
        value: `${title}`,
    });

    return (
        <Box sx={sxPageStyles.helpRequestFilterSelect}>
            <FormControl sx={sxPageStyles.formControl}>
                <Select
                    placeholder="Course set"
                    id="module-collection-select-multiple-chip"
                    isMulti
                    value={selectedModuleCollections.map((name) =>
                        moduleCollectionNameToOption(name),
                    )}
                    onChange={handleModuleCollectionSelect}
                    options={moduleCollectionNames
                        .sort()
                        .map(moduleCollectionNameToOption)}
                />
            </FormControl>
        </Box>
    );
};

export default ModuleCollectionSelect;
