import { Box, CircularProgress, IconButton, IconButtonProps } from '@mui/material';
import { RefreshOutlined } from '@mui/icons-material';
import React from 'react';

export interface RefreshButtonPropsI extends IconButtonProps {
    loading: boolean;
}

export const RefreshButton = (props: RefreshButtonPropsI) => {
    const PROGRESS_SIZE = 30;
    if (props.loading) {
        return (
            <Box paddingX={1} paddingY={1}>
                <CircularProgress size={PROGRESS_SIZE} />
            </Box>
        );
    }

    return (
        <Box>
            <IconButton onClick={props.onClick} size="large">
                <RefreshOutlined />
            </IconButton>
        </Box>
    );
};

export default RefreshButton;
