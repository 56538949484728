import { Box, Typography, Paper } from '@mui/material';
import React, { useContext } from 'react';

import HelpRequestResponseContainerWithContext from '../../components/help-request/teacher/view/help-request-response-container-with-context';

import RestrictedByRole from '../../components/restricted-by-role';

import sxPageStyles from '../../components/help-request/styles/page-styles';

import RefreshButton from '../../components/help-request/button/refresh-button';
import HelpRequestAdminPageDataProvider, {
    HelpRequestAdminPageDataContext,
} from '../../components/help-request/help-request-teacher-page-data-provider';
import HelpRequestAdminPageFilterProvider from '../../components/help-request/help-request-teacher-page-filter-provider';
import HelpRequestFilterPanel from '../../components/help-request/teacher/view/help-request-filter-panel';
import HelpRequestListContainer from '../../components/help-request/teacher/view/help-request-list-container';
import HelpRequestSummaryBar from '../../components/help-request/teacher/view/help-request-summary-bar';

const HelpRequestAdminPage = () => {
    const { helpRequestsLoading, fetchHelpRequests } = useContext(HelpRequestAdminPageDataContext);

    return (
        <Box component="main" sx={sxPageStyles.root}>
            <Paper
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 1,
                    margin: (theme) => theme.spacing(1, 1, 0, 1),
                }}
            >
                <Box sx={sxPageStyles.listTitleInline}>
                    <Box sx={sxPageStyles.contentRefreshButton}>
                        <RefreshButton loading={helpRequestsLoading} onClick={fetchHelpRequests} />
                    </Box>
                    <Typography sx={{ whiteSpace: 'nowrap' }} variant="h2">
                        Help requests
                    </Typography>
                </Box>
                <HelpRequestSummaryBar />
            </Paper>
            <Box sx={sxPageStyles.evenRatioColumnLayout}>
                <Box component="section" sx={sxPageStyles.evenRatioColumn}>
                    <Box sx={sxPageStyles.columnContent}>
                        <Paper elevation={2} sx={sxPageStyles.helpRequestList}>
                            <Box sx={sxPageStyles.listFilterContainer}>
                                <HelpRequestFilterPanel />
                                <HelpRequestListContainer />
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box component="section" sx={sxPageStyles.evenRatioColumn}>
                    <Box sx={sxPageStyles.columnContent}>
                        <HelpRequestResponseContainerWithContext />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const HelpRequestAdminPageWithDataAndFilterProvider = (props: any) => (
    <HelpRequestAdminPageDataProvider {...props}>
        <HelpRequestAdminPageFilterProvider {...props}>
            <HelpRequestAdminPage {...props} />
        </HelpRequestAdminPageFilterProvider>
    </HelpRequestAdminPageDataProvider>
);

const HelpRequestAdminPageWithRestriction = (props: any) => (
    <RestrictedByRole role="ADMIN">
        <HelpRequestAdminPageWithDataAndFilterProvider {...props} />
    </RestrictedByRole>
);

export default HelpRequestAdminPageWithRestriction;
