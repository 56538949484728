import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';

import CodeViewer from '../../../assignment/programming/code-viewer';
import TestResult from '../../../assignment/programming/test-result';
import {
    ProgrammingAssignmentHelpRequestI,
    ProgrammingAssignmentI,
    SubmissionI,
} from '../../types';

interface HelpRequestContentProps {
    helpRequest: ProgrammingAssignmentHelpRequestI | undefined;
    submission: SubmissionI | undefined;
    programmingAssignment: ProgrammingAssignmentI | undefined;
}

const objectIsEmpty = (o: Record<string, any>) =>
    o && Object.keys(o).length === 0 && o.constructor === Object;

const HelpRequestContent = ({
    helpRequest,
    submission,
    programmingAssignment,
}: HelpRequestContentProps) => {
    const { i18n } = useTranslation();
    const PAGE_LANGUAGE = (i18n?.language || 'en') as 'en' | 'fi';

    const hasContent = helpRequest?.content && !objectIsEmpty(helpRequest?.content);
    const content =
        helpRequest &&
        hasContent &&
        (helpRequest?.content[PAGE_LANGUAGE] || Object.values(helpRequest?.content)[0]);
    const answers = content ? Object.entries(content.answers) : [];
    const hasAnswers = answers.length > 0;

    const answerComponents = () =>
        answers.map(([key, value]: [string, string]) => (
            <HelpRequestAnswers key={key} question={key} answer={value} />
        ));

    if (!(helpRequest && programmingAssignment && submission)) return null;

    return (
        <Box
            id="help-request-content-container"
            display="flex"
            flexDirection="column"
            overflow="hidden"
        >
            <Box pt={1}>
                {hasAnswers && answerComponents()}
                <SubmissionCodeViewer submission={submission} />
                <TestResults submission={submission} />
                <Typography sx={{ m: 2 }} className="help-request-help-request-id-subtitle">
                    {`Request id: ${helpRequest.helpRequestUuid}`}
                </Typography>
            </Box>
        </Box>
    );
};

interface TestResultsI {
    submission?: SubmissionI;
}

interface TestResultI {
    passed: boolean;
    testName: string;
    testErrors: string;
}

const TestResults = ({ submission }: TestResultsI) => {
    const { t } = useTranslation();

    if (!submission) {
        return <Box m={2}>{'-'}</Box>;
    }

    const testResultsIsArray = Array.isArray(submission.testResults);

    return (
        <Box m={2}>
            <Typography variant="h3" gutterBottom>
                <Trans i18nKey="testResults" />
            </Typography>
            {testResultsIsArray && (submission.testResults.length > 0 || submission.testErrors) && (
                <>
                    {submission.testResults.map((testResult: TestResultI, i) => (
                        <TestResult key={`testResult-${i}`} testResult={testResult} />
                    ))}
                    {submission.testErrors && (
                        <TestResult
                            testResult={{
                                passed: false,
                                testName: t('showErrorOutput'),
                                testErrors: submission.testErrors,
                            }}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

const HelpRequestAnswers = ({ question, answer }: { question: string; answer: string }) => (
    <Box display="flex" flexDirection="column" px={2} pb={2}>
        <Typography variant="subtitle2" gutterBottom>
            {`${question}:`}
        </Typography>
        <Typography gutterBottom>{answer}</Typography>
    </Box>
);

const SubmissionCodeViewer = ({ submission }: { submission: SubmissionI | undefined }) => {
    const createTabElement = (name: string, code: string) => [
        name?.toUpperCase(),
        <div key={`code-view-${name}`} className="gatsby-highlight" data-language={'dart'}>
            <pre className={'language-dart'}>
                <code className={'language-dart'}>{code}</code>
            </pre>
        </div>,
    ];

    const getTabElements = (submission: SubmissionI | undefined) => {
        if (submission) {
            return Object.entries(submission.code)?.map(([name, code]) =>
                createTabElement(name, code),
            );
        }
        return [['No code', '']];
    };

    return (
        <Box px={2} marginBottom={2} fontSize="16px">
            <CodeViewer id={submission?.id} tabElements={getTabElements(submission)} />
        </Box>
    );
};

export default HelpRequestContent;
