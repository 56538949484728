import { Box, CircularProgress, List, TablePagination } from '@mui/material';
import React from 'react';
import {
    HelpRequestStatus,
    ProgrammingAssignmentHelpRequestI,
    ProgrammingAssignmentI,
    SubmissionI,
} from '../../types';
import HelpRequestListItem from './help-request-list-item';

interface HelpRequestListProps {
    programmingAssignmentHelpRequests: Array<ProgrammingAssignmentHelpRequestI> | undefined;
    selectedHelpRequest: ProgrammingAssignmentHelpRequestI | undefined;
    handleHelpRequestSelect: (
        helpRequest: ProgrammingAssignmentHelpRequestI,
    ) => React.MouseEventHandler<HTMLDivElement> | undefined;
    submissions: Record<string, SubmissionI>;
    programmingAssignments: Record<string, ProgrammingAssignmentI>;
    toggleStatusFilter: (status: HelpRequestStatus) => () => void;
    toggleUserFilter: (id: string) => () => void;
    toggleProgrammingAssignmentFilter: (id: string) => () => void;
    toggleTemplateFilter: (id: string) => () => void;
    handleSetDateValue: (endpoint: 'startDate' | 'endDate', dateToSet: Date) => void;
}

const HelpRequestList = ({
    programmingAssignmentHelpRequests,
    selectedHelpRequest,
    handleHelpRequestSelect,
    ...rest
}: HelpRequestListProps) => {
    if (!programmingAssignmentHelpRequests) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="100%"
                pt={2}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <List dense>
            {programmingAssignmentHelpRequests.map(
                (helpRequest: ProgrammingAssignmentHelpRequestI) => (
                    <HelpRequestListItem
                        key={helpRequest.helpRequestUuid}
                        helpRequest={helpRequest}
                        onClick={handleHelpRequestSelect(helpRequest)}
                        selectedHelpRequest={selectedHelpRequest}
                        {...rest}
                    />
                ),
            )}
        </List>
    );
};

export default HelpRequestList;
