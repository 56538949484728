import { Box, FormControl } from '@mui/material';
import React, { useContext } from 'react';
import Select from 'react-select';
import { getEllipsisText } from '../../../../../util/string-util';
import { HelpRequestAdminPageDataContext } from '../../../help-request-teacher-page-data-provider';
import { HelpRequestAdminPageFilterContext } from '../../../help-request-teacher-page-filter-provider';
import sxPageStyles from '../../../styles/page-styles';

export const ProgrammingAssignmentSelect = () => {
    const { programmingAssignmentTitles } = useContext(HelpRequestAdminPageDataContext);

    const { selectedProgrammingAssignments, handleProgrammingAssignmentSelect } = useContext(
        HelpRequestAdminPageFilterContext,
    );

    const programmingAssignmentTitleToOption = (title: string) => ({
        label: `${getEllipsisText(title, 30)}`,
        value: `${title}`,
    });

    return (
        <Box sx={sxPageStyles.programmingAssignmentSelect}>
            <FormControl sx={sxPageStyles.formControl}>
                <Select
                    placeholder="Programming assignment"
                    id="programming-assignment-select-multiple-chip"
                    isMulti
                    value={selectedProgrammingAssignments.map((title) =>
                        programmingAssignmentTitleToOption(title),
                    )}
                    onChange={handleProgrammingAssignmentSelect}
                    options={programmingAssignmentTitles
                        .sort()
                        .map(programmingAssignmentTitleToOption)}
                />
            </FormControl>
        </Box>
    );
};

export default ProgrammingAssignmentSelect;
